<template lang="pug">
  .price-row
    template(v-for="(value, key) in fees")
      PriceItem(
        :fee-type="feeType"
        :fee="key"
        :value="value"
        :ota-name="otaName"
      )
    .remove-action
      FaIcon(
        icon="trash-alt"
        @click="$emit('remove-row')"
      )
</template>

<script>
  export default {
    components: {
      PriceItem: () => import("./PriceItem")
    },

    props: {
      fees: {
        type: Object,
        default: () => new Object()
      },
      feeType: {
        type: String,
        required: true
      },
      otaName: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .price-row
    display: flex

    .remove-action
      padding: 20px 10px 0
      color: $default-purple

      svg
        cursor: pointer
</style>
