import { render, staticRenderFns } from "./PricesRow.vue?vue&type=template&id=5e611874&scoped=true&lang=pug&"
import script from "./PricesRow.vue?vue&type=script&lang=js&"
export * from "./PricesRow.vue?vue&type=script&lang=js&"
import style0 from "./PricesRow.vue?vue&type=style&index=0&id=5e611874&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e611874",
  null
  
)

export default component.exports